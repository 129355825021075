<script setup lang="ts">
import { onMounted, ref, toRaw, type Ref } from 'vue';
import axiosApiInstance from '@/helpers/api.axios.headers';
import { useExtraStore } from '@/stores/extra';
import Datepicker from '@vuepic/vue-datepicker';
import IconTrashBin from '@/components/icons/IconTrashBin.vue';
import FullscreenLoader from '@/components/animations/FullscreenLoader.vue';
import router from '@/router';
import type { ExtraInterface, Pricing } from '@/types/extra/extra.interface';
import { useRoute } from 'vue-router';


const fullscreenLoader = ref(false);
const extraStore: ExtraInterface  = useExtraStore();

const route = useRoute();
const extraId: Ref<string> = ref(String(route.params.id));
const isEdit: Ref<boolean> = ref(false);

const createdBy: Ref<string> = ref('');
const editedBy: Ref<string> = ref('');
const createdAt: Ref<string> = ref('');
const editedAt: Ref<string> = ref('');

const flightCriteria: Ref<string> = ref('');
const paxCriteria: Ref<string> = ref('');
const paxTypeCriteria: Ref<string> = ref('');



onMounted(() => {
    
    if(extraId.value != ''){
        isEdit.value = true;
    }

    if (isEdit.value) {

        fullscreenLoader.value = true;
        axiosApiInstance
        .get(`/extra_services/get/${extraId.value}`, {

        })
        .then(function (response) {
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                console.log(
                    extraStore.pricing[0].priceIntervals[0].criteria[0]
                );
                
                extraStore.pricing = response.data.extra_services[0].pricing;
                extraStore.name = response.data.extra_services[0].name;
                extraStore.description = response.data.extra_services[0].description;


                extraStore.commission = response.data.extra_services[0].commission;
                extraStore.commissionType = response.data.extra_services[0].commissionType;
                extraStore.markup = response.data.extra_services[0].markup;
                extraStore.markupType = response.data.extra_services[0].markupType;

                extraStore.priceIntervals = response.data.extra_services[0].priceIntervals;

                createdBy.value = response.data.extra_services[0].created_by_name;
                editedBy.value = response.data.extra_services[0].edited_by_name;
                createdAt.value = response.data.extra_services[0].created_at;
                editedAt.value = response.data.extra_services[0].updated_at;

                flightCriteria.value = extraStore.pricing[0].priceIntervals[0].criteria[0].flightCriteria;
                paxCriteria.value = extraStore.pricing[0].priceIntervals[0].criteria[0].paxCriteria;
                paxTypeCriteria.value = extraStore.pricing[0].priceIntervals[0].criteria[0].paxTypeCriteria ;
            }
            fullscreenLoader.value = false;
        })
        .catch(function (error) {
            alert(error);
        });
    }else{
        useExtraStore().resetExtra();
    }

});

const validateInput = (event: Event) => {

    const target = event.target as HTMLInputElement;
    const value = target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, '');

    // Update the input field value directly
    target.value = sanitizedValue;
};

const validateInputWithMax = (event: Event) => {
    const target = event.target as HTMLInputElement;

    // Get the input value
    let value = target.value;

    // Remove any non-numeric characters or negative signs
    value = value.replace(/[^0-9]/g, '');

    // Convert to a number and enforce max value of 18
    let numericValue = parseInt(value, 10);
    if (numericValue > 18) {
        numericValue = 18;
    }

    // Update the input field value directly
    target.value = numericValue.toString();
};


const onDateUpdate = (value: string[]) => {
    
    if (value[1] == null) {
        const startDate = new Date(value[0]); 
        const endDate = new Date(startDate);  
        endDate.setDate(startDate.getDate() + 7); // Add 7 days to the start date

        // Update value[1] with the new end date
        value[1] = endDate.toISOString().split('T')[0]; // Convert the date back to a string in the format 'YYYY-MM-DD'

        console.log('Updated value[1]:', value[1]);
    }
}

const format = (date: Array<Date>) => {
    date = toRaw(date);

    const day = date[0].getDate();
    const month = date[0].getMonth() + 1;
    const year = date[0].getFullYear();

    if (date[1] != null) {
        const day1 = date[1].getDate();
        const month1 = date[1].getMonth() + 1;
        const year1 = date[1].getFullYear();

        return `${day}/${month}/${year} - ${day1}/${month1}/${year1}`;
    }
};

const addDateInterval = () => {

    flightCriteria.value = '';
    paxCriteria.value = '';
    paxTypeCriteria.value = '';

    extraStore.pricing.push({
        dateInterval: [new Date(), new Date()],
        priceIntervals: [
            {
                from: '',
                to: '',
                criteria: []
            }
        ]
        
    });
}

const deleteDateInterval = (index: number) => {
    extraStore.pricing.splice(index, 1);
}

const addNewPriceInterval = () => {

    flightCriteria.value = '';
    paxCriteria.value = '';
    paxTypeCriteria.value = '';

    const intervals = extraStore.priceIntervals;
    const lastInterval = intervals[intervals.length - 1];

    if(lastInterval && lastInterval.to == 0){
        alert('Add a price interval');
        return;
    }
    

    if (lastInterval && lastInterval.to != 0) {

        intervals.push({
            from: lastInterval.to + 1, // Start 'from' where the previous 'to' left off
            to: lastInterval.to + 1000
        });
    } else {
        intervals.push({
            from: 0,
            to: 1000
        });
    }

    // Update pricing.priceIntervals for each pricing entry
    extraStore.pricing.forEach((pricing: Pricing, index: number) => {
        extraStore.pricing[index].priceIntervals = intervals.map(interval => ({
            ...interval,
            criteria: []
        }));
    });

}

const updatePricing = (event: Event | null = null) => {

    if(event != null){

        validateInput(event);
    }

    const intervals = extraStore.priceIntervals;

    flightCriteria.value = '';
    paxCriteria.value = '';
    paxTypeCriteria.value = '';

        // Update pricing.priceIntervals for each pricing entry
    extraStore.pricing.forEach((pricing: Pricing, index: number) => {
        extraStore.pricing[index].priceIntervals = intervals.map(interval => ({
            ...interval,
            criteria: [
                {
                    paxCriteria: '',
                    paxTypeCriteria: '',
                    flightCriteria: '',
                    paxType: [
                        {
                            adult: true,
                            ageFrom: '',
                            ageTo: '',
                            pricingType: 'flat',
                            value: 100,
                            isNull: true
                        }
                    ]
                }
            ]
        }));
    });

}

const deletePriceInterval = (index: number) => {

    if(extraStore.priceIntervals.length === 1){
        extraStore.priceIntervals[0].from = 0;
        extraStore.priceIntervals[0].to = 0;
    }else{
        extraStore.priceIntervals.splice(
            index,
            1
        )
    }

    
    updatePricing();
}

const updateCriteria = (type: string, value: string) => {

    if(type === 'flightCriteria'){
        if(value === 'perItenerary'){
            
            extraStore.pricing.forEach( (pricing) => {

                pricing.priceIntervals.forEach( (priceInterval) => {

                    priceInterval.criteria = [
                                    {
                                        paxCriteria: '',
                                        paxTypeCriteria: '',
                                        flightCriteria: value,
                                        paxType: [
                                            {
                                                adult: true,
                                                ageFrom: '',
                                                ageTo: '',
                                                pricingType: 'flat',
                                                value: 100,
                                                isNull: true
                                            }
                                        ]
                                    },
                                ]
                    
                    
                });
                
            });

        }else if(value === 'perWay'){
            extraStore.pricing.forEach( (pricing) => {

                pricing.priceIntervals.forEach( (priceInterval) => {

                    priceInterval.criteria = [
                                    {
                                        paxCriteria: '',
                                        paxTypeCriteria: '',
                                        flightCriteria: value,
                                        paxType: [
                                            {
                                                adult: true,
                                                ageFrom: '',
                                                ageTo: '',
                                                pricingType: 'flat',
                                                value: 100,
                                                isNull: true
                                            }
                                        ]
                                    },
                                    {
                                        paxCriteria: '',
                                        paxTypeCriteria: '',
                                        flightCriteria: value,
                                        paxType: [
                                            {
                                                adult: true,
                                                ageFrom: '',
                                                ageTo: '',
                                                pricingType: 'flat',
                                                value: 100,
                                                isNull: true
                                            },
                                        ]
                                    }
                                ]
                    
                    
                });


            });
        }
    }

    if(type === 'paxCriteria'){

        extraStore.pricing.forEach( (pricing) => {

                pricing.priceIntervals.forEach( (priceInterval) => {

                    priceInterval.criteria.forEach((criteria) => {

                        criteria.paxCriteria = value;
                        
                    });

                    
                });
                
            });

    }
    if(type === 'paxTypeCriteria'){

        extraStore.pricing.forEach( (pricing) => {

                pricing.priceIntervals.forEach( (priceInterval) => {

                    priceInterval.criteria.forEach((criteria) => {

                        criteria.paxTypeCriteria = value;
                        
                    });

                    
                });
                
            });

    }
    

}

const addNewChild = () => {


    if(extraStore.pricing[0].priceIntervals[0].criteria[0].paxType.length > 4 ){
        return;
    }

    extraStore.pricing.forEach( (pricing) => {

        pricing.priceIntervals.forEach( (priceInterval) => {

            priceInterval.criteria.forEach((criteria) => {

                criteria.paxType.push(
                    {
                        adult: false,
                        ageFrom: '',
                        ageTo: '',
                        pricingType: "flat",
                        value: 100,
                        isNull: false
                    }
                )
                
            });

            
        });

    });
}

const updateChildAge = (type:string, event: Event, index: number) => {

    if(event != null){

        validateInputWithMax(event);
    }

    const target = event.target as HTMLInputElement;
    const value = target.value;

    extraStore.pricing.forEach( (pricing) => {

        pricing.priceIntervals.forEach( (priceInterval) => {

            priceInterval.criteria.forEach((criteria) => {

                criteria.paxType.forEach((pax, i: number) => {
                    
                    if(type === 'from' && !pax.adult && index === i){
                        pax.ageFrom = value;
                    }
                    if(type === 'to' && !pax.adult && index === i){
                        pax.ageTo = value;
                    }
                });
                
            });

            
        });

    });

}

const deleteChildInterval = (index: number) => {
    extraStore.pricing.forEach( (pricing) => {

        pricing.priceIntervals.forEach( (priceInterval) => {

            priceInterval.criteria.forEach((criteria) => {

                criteria.paxType.splice(
                    index,
                    1
                );

                
                
            });

            
        });

    });
}

const addExtra = () => {

    fullscreenLoader.value = true;
    const dataToSend = {
        name: extraStore.name,
        description: extraStore.description,
        status: extraStore.status,
        commission: extraStore.commission,
        commissionType: extraStore.commissionType,
        markup: extraStore.markup,
        markupType: extraStore.markupType,
        priceIntervals: extraStore.priceIntervals,
        pricing: extraStore.pricing
    }

    let checkDates = {
        status: true,
        message: ''
    };
    if(extraStore.checkDateRangesOverlap){

        checkDates = extraStore.checkDateRangesOverlap(dataToSend.pricing);
    }


    if(!checkDates.status){
        alert(checkDates.message);
        fullscreenLoader.value = false;
        return false;
    }

    axiosApiInstance
        .post('/extra_services/create', {
            ...dataToSend,
        })
        .then(function (response) {
            fullscreenLoader.value = false;
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                alert(response.data.message);
                router.push('/extra/list');
            }
        })
        .catch(function (error) {
            alert(error);
        });
}

const editExtra = (id: string) => {

    fullscreenLoader.value = true;

    const dataToSend = {
        name: extraStore.name,
        description: extraStore.description,
        status: extraStore.status,
        commission: extraStore.commission,
        commissionType: extraStore.commissionType,
        markup: extraStore.markup,
        markupType: extraStore.markupType,
        priceIntervals: extraStore.priceIntervals,
        pricing: extraStore.pricing
    }
    
    let checkDates = {
        status: true,
        message: ''
    };
    if(extraStore.checkDateRangesOverlap){

        checkDates = extraStore.checkDateRangesOverlap(dataToSend.pricing);
    }


    if(!checkDates.status){
        alert(checkDates.message);
        fullscreenLoader.value = false;
        return false;
    }

    axiosApiInstance
        .post(`/extra_services/update/${id}`, {
            ...dataToSend,
        })
        .then(function (response) {
            fullscreenLoader.value = false;
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                alert(response.data.message);
                router.push('/extra/list');
            }
        })
        .catch(function (error) {
            alert(error);
        });
}

</script>
<template>
    <FullscreenLoader v-show="fullscreenLoader" />
    <div class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800">
        <div class="container p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
            <div>
                <div class="text-xl underline underline-offset-2">
                    General Information
                </div>
                <div 
                    v-if="isEdit"
                    class="ml-3 text-gray-600 italic"
                >
                    <div v-if="createdBy != '' && createdAt != ''">
                        Created by <b>{{ createdBy }}</b> at <b>{{ createdAt }}</b>
                    </div>
                    <div v-if="editedBy != '' && editedAt != ''">
                        Last edit by <b>{{ createdBy }}</b> at <b>{{ createdAt }}</b>
                    </div>
                </div>
                <div class="p-4">
                    <div class="col-span-6 sm:col-span-3 mr-2 mb-4">
                        <label class="block text-gray-700">
                            &#8544;. Name
                        </label>
                        <input type="text" autocomplete="off" class="form-control mt-1" v-model="extraStore.name">
                        <div class="text-sm">
                            <p class="text-gray-500">Name of the extra service</p>
                        </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3 mr-2 mb-4">
                        <label class="block text-gray-700">
                            &#8545;. Description
                        </label>
                        <textarea autocomplete="off" class="form-control mt-1" v-model="extraStore.description" rows="4">
                                    </textarea>
                        <div class="text-sm">
                            <p class="text-gray-500">Write extra Information regarding this extra service</p>
                        </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3 mr-2 mb-4">
                        <label class="block text-gray-700">
                            &#8546;. Status
                        </label>
                        <div class="flex">
                            <div class="mr-4 border-r-2 pr-4 w-60">
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input id="statusAvailable" type="radio" name="status"
                                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                            value="available" v-model="extraStore.status">
                                    </div>
                                    <div class="ml-3 text-sm"><label for="statusAvailable"
                                            class="cursor-pointer">Available</label>
                                        <p class="text-gray-500">Set Extra Status Available</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mr-4 pr-4">
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input id="statusOnReq" type="radio" name="status"
                                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                            value="onRequest" v-model="extraStore.status">
                                    </div>
                                    <div class="ml-3 text-sm"><label for="statusOnReq" class="cursor-pointer">On
                                            Request</label>
                                        <p class="text-gray-500">Set Extra Status On Request</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="text-xl underline underline-offset-2">
                    Pricing & Configuration
                </div>
                <div class="p-4">
                    <div class="col-span-6 sm:col-span-3 mr-2 mb-4">
                        <label class="block text-gray-700">
                            &#8544;. Price commissions and markup
                        </label>
                        <div class="text-sm">
                            <p class="text-gray-500">Add commissions and markup</p>
                        </div>
                        <div>
                            <div class="flex">
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="commissions" class="block text-gray-700">Commission value</label>
                                    <input
                                        type="text"
                                        id="commissions"
                                        v-model="extraStore.commission"
                                        @input="validateInput"
                                        class="form-control mt-1"
                                    />
                                    <div class="ml-3 text-sm">
                                        <p class="text-gray-500">
                                            Add commission value
                                        </p>
                                    </div>
                                </div>
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="commissions" class="block text-gray-700">Commission type</label>
                                    <select class="form-select w-full" 
                                        id=""
                                        v-model="extraStore.commissionType"
                                    >
                                        <option value="flat">flat</option>
                                        <option value="percent">percent</option>
                                    </select>
                                    <div class="ml-3 text-sm">
                                        <p class="text-gray-500">
                                            Add commission type
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="commissions" class="block text-gray-700">Markup value</label>
                                    <input
                                        type="text"
                                        id="commissions"
                                        v-model="extraStore.markup"
                                        @input="validateInput"
                                        class="form-control mt-1"
                                    />
                                    <div class="ml-3 text-sm">
                                        <p class="text-gray-500">
                                            Add markup value
                                        </p>
                                    </div>
                                </div>
                                <div class="col-span-6 sm:col-span-3 mr-2">
                                    <label for="commissions" class="block text-gray-700">Markup type</label>
                                    <select class="form-select w-full" 
                                        id=""
                                        v-model="extraStore.markupType"
                                    >
                                        <option value="flat">flat</option>
                                        <option value="percent">percent</option>
                                    </select>
                                    <div class="ml-3 text-sm">
                                        <p class="text-gray-500">
                                            Add markup type
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3 mr-2 mb-4">
                        <label class="block text-gray-700">
                            &#8545;. Date Intervals
                        </label>
                        <div class="text-sm">
                            <p class="text-gray-500">Add availability intervals, if no intervals are added, the the extra
                                will
                                always be available</p>
                        </div>
                        <button class="btn-primary" @click="addDateInterval">
                            Add new Interval
                        </button>
                        <div v-for="(interval, index) in extraStore.pricing" :key="index"
                            class="flex items-center">
                            <div class="me-2">
                                {{ index + 1 }}
                            </div>
                            <Datepicker 
                                range 
                                v-model="interval.dateInterval" 
                                :enableTimePicker="false"  
                                :clearable="false" 
                                multi-calendars 
                                @update:modelValue="onDateUpdate"
                                :format="format"
                                :previewFormat="format"
                            />
                            <div v-if="extraStore.pricing.length > 1" class="me-2">
                                <button type="button" title="Delete this interval"
                                    class="flex items-center py-1 px-2 mx-2 text-xs font-medium text-center text-white bg-red-700 rounded hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                    @click="deleteDateInterval(index)">
                                    <IconTrashBin />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3 mr-2 mb-4">
                        <label class="block text-gray-700">
                            &#8546;. Package Price Intervals
                        </label>
                        <div class="text-sm">
                            <p class="text-gray-500">
                                Add price intervals, leave interval 0 to 0 for all price ranges
                                range
                            </p>
                        </div>
                        <button class="btn-primary" @click="addNewPriceInterval">
                            Add New Price Interval
                        </button>
                        <div v-for="(interval, index) in extraStore.priceIntervals" :key="index"
                            class="flex items-center">
                            <div class="me-2">
                                {{ index + 1 }}
                            </div>
                            <div class="me-2">
                                <input type="number" autocomplete="off" data-pricing-from v-model.number="interval.from" @input="updatePricing"
                                    class="form-control mt-1" />
                            </div>
                            <div class="me-2">
                                <input type="number" autocomplete="off" data-pricing-to v-model.number="interval.to" @input="updatePricing"
                                    class="form-control mt-1" />
                            </div>
                            <div class="me-2">
                                <button type="button" title="Delete this interval"
                                    class="flex items-center py-1 px-2 mx-2 text-xs font-medium text-center text-white bg-red-700 rounded hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                    @click="deletePriceInterval(index)">
                                    <IconTrashBin />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3 mr-2 mb-4">
                        <label class="block text-gray-700">
                            &#8547;. Flight criteria
                        </label>
                        <div class="text-sm">
                            <p class="text-gray-500">Choose if pricing should be for the entire itenerary or per each
                                way(twice)
                            </p>
                        </div>
                        <div class="flex">
                            <div class="mr-4 border-r-2 pr-4 w-60">
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input id="perItenerary" type="radio" name="flightCriteria"
                                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                            value="perItenerary" v-model="flightCriteria" @change="updateCriteria('flightCriteria', 'perItenerary')">
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="perItenerary" class="cursor-pointer">Per Itenerary</label>
                                        <p class="text-gray-500">Set Extra Per Itenerary</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mr-4 pr-4">
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input id="perWay" type="radio" name="flightCriteria"
                                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                            value="perWay"  v-model="flightCriteria" @change="updateCriteria('flightCriteria', 'perWay')">
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="perWay" class="cursor-pointer">Per Way</label>
                                        <p class="text-gray-500">Set Extra Per Way</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                        v-if="flightCriteria != ''" 
                        class="col-span-6 sm:col-span-3 mr-2 mb-4"
                    >
                        <label class="block text-gray-700">
                            &#8547;. Pax criteria
                        </label>
                        <div class="text-sm">
                            <p class="text-gray-500">Choose if pricing should be for the service or per pax</p>
                        </div>
                        <div class="flex">
                            <div class="mr-4 border-r-2 pr-4 w-60">
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input id="perService" type="radio" name="paxCriteria"
                                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                            value="perService" v-model="paxCriteria" @change="updateCriteria('paxCriteria', 'perService')">
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="perService" class="cursor-pointer">Per Service</label>
                                        <p class="text-gray-500">Set Extra Per Service</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mr-4 pr-4">
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input id="perPax" type="radio" name="paxCriteria"
                                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                            value="perPax" v-model="paxCriteria" @change="updateCriteria('paxCriteria', 'perPax')">
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="perPax" class="cursor-pointer">Per Pax</label>
                                        <p class="text-gray-500">Set Extra Per Pax</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                        v-if="paxCriteria === 'perPax'"
                        class="col-span-6 sm:col-span-3 mr-2 mb-4"
                    >
                        <label class="block text-gray-700">
                            &#8548;. Pax Type criteria
                        </label>
                        <div class="text-sm">
                            <p class="text-gray-500">Choose if pricing should be the same for all pax or separate</p>
                        </div>
                        <div class="flex">
                            <div class="mr-4 border-r-2 pr-4 w-60">
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input id="perAll" type="radio" name="paxTypeCriteria"
                                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                            value="perAll" v-model="paxTypeCriteria" @change="updateCriteria('paxTypeCriteria', 'perAll')">
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="perAll" class="cursor-pointer">Per All</label>
                                        <p class="text-gray-500">Set Extra Per All the same</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mr-4 pr-4">
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input id="perPaxType" type="radio" name="paxTypeCriteria"
                                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
                                            value="perPaxType" v-model="paxTypeCriteria" @change="updateCriteria('paxTypeCriteria', 'perPaxType')">
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="perPaxType" class="cursor-pointer">Per Pax Type</label>
                                        <p class="text-gray-500">Set Extra Per Pax Type</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                        v-if="paxTypeCriteria === 'perPaxType'"
                        class="col-span-6 sm:col-span-3 mr-2 mb-4">
                        <label class="block text-gray-700">
                            &#8549;. Child settings
                        </label>
                        <div class="text-sm">
                            <p class="text-gray-500">Define child ages</p>
                        </div>
                        <button class="btn-primary" @click="addNewChild">
                            Add New Child Age Interval
                        </button>
                        <div v-for="(pax, index) in extraStore.pricing[0].priceIntervals[0].criteria[0].paxType" :key="index"
                            >
                            <div class="flex items-center" v-if="!pax.adult">
                                <div class="me-2">
                                    {{ index }}
                                </div>
                                <div class="me-2">
                                    <input type="number" placeholder="Age from" autocomplete="off" :max="18" v-model="pax.ageFrom" @input="updateChildAge('from', $event, index)"
                                        class="form-control mt-1" />
                                </div>
                                <div class="me-2">
                                    <input type="number" placeholder="Age to" autocomplete="off" :max="18" v-model="pax.ageTo" @input="updateChildAge('to', $event, index)"
                                        class="form-control mt-1" />
                                </div>
                                <div class="me-2">
                                    <button type="button" title="Delete this interval"
                                        class="flex items-center py-1 px-2 mx-2 text-xs font-medium text-center text-white bg-red-700 rounded hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                        @click="deleteChildInterval(index)">
                                        <IconTrashBin />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="flightCriteria != '' && paxCriteria === 'perService' || flightCriteria != '' && ( paxCriteria === 'perPax' && paxTypeCriteria != '') ">
            <label class="block text-gray-700">
                &#8550;. Complete the following table or tables with values
            </label>
            <table class="table-bordered mb-4" v-for="(priceObj, index) in extraStore.pricing" :key="index">
                <tr>
                    <td>
                        <div class="p-2 bg-slate-200 text-lg">
                            <div v-if="priceObj.dateInterval[0] != null && priceObj.dateInterval[1] != null">
                                {{ new Date(priceObj.dateInterval[0]).toLocaleDateString('en-GB').replace(/\//g, '-') }} to  {{ new Date(priceObj.dateInterval[1]).toLocaleDateString('en-GB').replace(/\//g, '-') }}
                            </div>
                            <div v-else>
                                Available for all time frames
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table class="nested-table" v-for="(priceIntervalObj, index2) in priceObj.priceIntervals" :key="index2">
                            <tr>
                                <td class="w-32">
                                    <div v-if="priceIntervalObj.from != 0 || priceIntervalObj.to != 0">
                                        {{ priceIntervalObj.from }} - {{ priceIntervalObj.to }}
                                    </div>
                                    <div v-else>
                                        All price ranges
                                    </div>
                                </td>
                                <td>
                                    <table class="nested-table" v-for="(criteria, index3) in priceIntervalObj.criteria" :key="index3">
                                        <tr>
                                            <td class="w-32">
                                                <div v-if="priceIntervalObj.criteria.length === 1">
                                                    <span>
                                                        per Itinerary
                                                    </span>
                                                </div>
                                                <div v-else>
                                                    <span v-if="index3 == 0">
                                                        Outbound
                                                    </span>
                                                    <span v-if="index3 == 1">
                                                        Inbound
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="flex">
                                                    <div class="border-l-2 w-3/12" v-for="(pax, index4) in criteria.paxType" :key="index4">
                                                        <div v-if="pax.adult" class="bg-slate-200">
                                                            <div class="p-4">
                                                                <div class="bg-slate-400 p-2">
                                                                    <span v-if="criteria.paxCriteria === 'perService'">
                                                                        Per service
                                                                    </span>
                                                                    <span v-else-if="criteria.paxTypeCriteria !== 'perAll'">
                                                                        Adult
                                                                    </span>
                                                                    <span v-else>
                                                                        Per All Pax
                                                                    </span>
                                                                </div>
                                                                <br>
                                                                <select class="form-select w-full" 
                                                                    id=""
                                                                    v-model="pax.pricingType"
                                                                    :disabled="pax.isNull"
                                                                >
                                                                    <option value="flat">flat</option>
                                                                    <option value="percentPerPack">percent from package value</option>
                                                                </select>
                                                                <br>
                                                                <input class="form-control" 
                                                                    v-model="pax.value"
                                                                    @input="validateInput"
                                                                    :disabled="pax.isNull"
                                                                    type="text" 
                                                                    placeholder="markup"
                                                                >
                                                                <br>
                                                                <div class="flex items-center justify-center mb-4">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        v-model="pax.isNull"
                                                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                                    >
                                                                    <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                                        Keep default pricing
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else class="bg-slate-200">
                                                            <div class="p-4">
                                                                <div class="bg-slate-300 p-2">
                                                                    Child ({{ pax.ageFrom }} - {{  pax.ageTo }})
                                                                </div>
                                                                <br>
                                                                <select class="form-select w-full" 
                                                                    id=""
                                                                    v-model="pax.pricingType"
                                                                    :disabled="pax.isNull"
                                                                >
                                                                    <option value="flat">flat</option>
                                                                    <option value="percentPerPack">percent from package value</option>
                                                                    <option value="percentFromAdult">percent from adult</option>
                                                                </select>
                                                                <br>
                                                                <input class="form-control" 
                                                                    v-model="pax.value"
                                                                    @input="validateInput"
                                                                    :disabled="pax.isNull"
                                                                    type="text" 
                                                                    placeholder="markup"
                                                                >
                                                                <br>
                                                                <div class="flex items-center justify-center mb-4">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        v-model="pax.isNull"
                                                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                                    >
                                                                    <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Keep default pricing</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>  
                        </table>
                    </td>
                </tr>
            </table>
        </div>
        <div v-if="isEdit">
            <button type="button" class="btn-success mt-1" @click="editExtra(extraId)">
                Edit Extra
            </button>
        </div>
        <div v-else>
            <button type="button" class="btn-success mt-1" @click="addExtra">
                Add Extra
            </button>
        </div>
    </div>
</template>

<style scoped>
.table-bordered {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

}

.table-bordered>tr>th,
.table-bordered>tr>td {
    border: 1px solid black;
    padding: 2px;
    text-align: center;
}

.nested-table {
    margin: -2px;
    width: calc(100% + 4px);
    border: 1px solid black;
    text-align: center;
}
.form-control:disabled, .form-select:disabled{
    opacity: .65;
}
</style>