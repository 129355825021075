<script setup lang="ts">
import { usePackageStore } from '@/stores/package';
import type { DateElement } from '@/types/builder/package.interface';
import { ref, type Ref } from 'vue';

const props = defineProps({
    dates: Object,
});

const store = usePackageStore();

const hasError: Ref<boolean> = ref(false);

const checkAllDestinations = (event: Event) => {
    Array.from(document.querySelectorAll('[data-transport-date]')).forEach((check) => {
        if ((check as HTMLInputElement).offsetParent !== null) {
            if ((event.target as HTMLInputElement).checked) {
                (check as HTMLInputElement).checked = true;
            } else {
                (check as HTMLInputElement).checked = false;
            }
        }
    });
};

const createDatesObjectForTransports = (event: Event) => {
    const datesArray: DateElement[] = [];

    Array.from(document.querySelectorAll('[data-transport-departure]')).forEach(
        (departureCheck) => {
            if (
                (departureCheck as HTMLInputElement).offsetParent !== null &&
                (departureCheck as HTMLInputElement).checked
            ) {
                hasError.value = false;

                const returnDatesArray: Array<string> = [];

                Array.from(
                    document.querySelectorAll(
                        `[data-transport-return-index='${departureCheck.getAttribute(
                            'data-transport-departure-index'
                        )}']`
                    )
                ).forEach((returnCheck) => {
                    if ((returnCheck as HTMLInputElement).offsetParent !== null) {
                        (returnCheck as HTMLInputElement).checked
                            ? returnDatesArray.push((returnCheck as HTMLInputElement).value)
                            : '';
                    }
                });

                datesArray.push({
                    departure: (departureCheck as HTMLInputElement).value,
                    lessFive: parseInt(String ((departureCheck as HTMLInputElement).dataset.transportLessFive)),
                    stopSale:  parseInt(String ((departureCheck as HTMLInputElement).dataset.transportStopSale)),
                    returns: returnDatesArray,
                });
            } else if (
                (departureCheck as HTMLInputElement).offsetParent !== null &&
                (departureCheck as HTMLInputElement).checked === false &&
                (event.target as HTMLElement).hasAttribute('data-transport-return') &&
                (departureCheck as HTMLElement).getAttribute('data-transport-departure-index') ==
                    (event.target as HTMLElement).getAttribute('data-transport-return-index')
            ) {
                hasError.value = true;
            }
        }
    );

    store.package.transport.dates = [...datesArray];

    store.package.commissions.hotelCalculationLogic = [];
    store.package.commissions.transportationCalculationLogic = [];
    store.package.cxlRules.cxl = [];

    store.package.transport.dates.forEach((date) => {
        store.package.commissions.hotelCalculationLogic.push({
            departureDate: String(date.departure),
            rule: [
                {
                    markup: 0,
                    markupType: 'flat',
                    calculationLogic: 'perPax',
                    commission: 0,
                    commissionType: 'flat',
                },
            ],
        });
        store.package.commissions.transportationCalculationLogic.push({
            departureDate: String(date.departure),
            rule: [],
        });
        store.package.cxlRules.cxl.push({
            departureDate: String(date.departure),
            cxlRules: [
                {
                    nonRefundable: false,
                },
            ],
        });
    });
};

function DaysBetween(StartDate: Date, EndDate: Date) {
    // The number of milliseconds in all UTC days (no DST)
    const oneDay = 1000 * 60 * 60 * 24;

    // A day in UTC always lasts 24 hours (unlike in other time formats)
    const start = Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate());
    const end = Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate());

    // so it's safe to divide by 24 hours
    return (start - end) / oneDay;
}

</script>
<template>
    <td class="px-6 py-4" colspan="99">
        <div class="text-xl underline underline-offset-2 text-black dark:text-white">
            Pair Departures With Returns
        </div>
        <div class="text text-black mt-2 dark:text-white">
            Departures For {{ props.dates?.departureCity }} - {{ props.dates?.destinationCity }}:
            <b>{{ props.dates?.startDate }} - {{ props.dates?.endDate }}</b>
            In The Selected Date Range
            <b
                >{{ store.package.generalInfo.period[0] }}
                to
                {{ store.package.generalInfo.period[1] }}</b
            >
            with Rotations
            <b>{{ store.package.generalInfo.minRotation }} days</b> to
            <b> {{ store.package.generalInfo.maxRotation }} days</b>
            <div class="flex items-center mb-4 mt-4">
                <input
                    :id="'checkAllDestinations' + props.dates?.routeId"
                    type="checkbox"
                    value=""
                    @change="
                        checkAllDestinations($event);
                        createDatesObjectForTransports($event);
                    "
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                    :for="'checkAllDestinations' + props.dates?.routeId"
                    class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                    >Check All Transports</label
                >
            </div>
            <div
                v-show="hasError"
                class="flex p-2 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
                role="alert"
            >
                <svg
                    aria-hidden="true"
                    class="flex-shrink-0 inline w-5 h-5 mr-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
                <span class="sr-only">Info</span>
                <div>Please select departure date</div>
            </div>
        </div>
        <hr class="my-3" />
        <div>
            <div
                class="flex"
                v-for="(dateDeparture, index3) in props.dates?.allDepartureDates"
                :key="index3"
            >
                <div class="border-r-2 pr-4">
                    <div class="flex">Departure Date:</div>
                    <br />
                    <div class="flex items-center mb-4">
                        <input
                            :id="'dateDeparture-' + dateDeparture.date"
                            type="checkbox"
                            :value="dateDeparture.date"
                            data-transport-date
                            data-transport-departure
                            :data-transport-less-five="dateDeparture.lessFive"
                            :data-transport-stop-sale="dateDeparture.stopSale"
                            :data-transport-departure-index="index3"
                            @change="createDatesObjectForTransports($event)"
                            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                            :for="'dateDeparture-' + dateDeparture.date"
                            class="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                            >{{ dateDeparture.date }}</label
                        >
                    </div>
                </div>
                <div class="ml-4">
                    <div>Return Dates:</div>
                    <br />
                    <div class="flex items-end">
                        <div
                            v-show="
                                Date.parse(String(dateDeparture.date)) <
                                    Date.parse(String(dateReturn)) &&
                                DaysBetween(
                                    new Date(String(dateDeparture.date)),
                                    new Date(String(dateReturn))
                                ) >= store.package.generalInfo.minRotation &&
                                DaysBetween(
                                    new Date(String(dateDeparture.date)),
                                    new Date(String(dateReturn))
                                ) <= store.package.generalInfo.maxRotation
                            "
                            v-for="(dateReturn, index4) in props.dates?.allReturnDates"
                            :key="index4"
                            class="flex items-center mb-4 mr-2"
                        >
                            <input
                                :id="'dateReturn-' + dateReturn + index3"
                                type="checkbox"
                                :value="dateReturn"
                                data-transport-date
                                data-transport-return
                                :data-transport-return-index="index3"
                                @change="createDatesObjectForTransports($event)"
                                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                                :for="'dateReturn-' + dateReturn + index3"
                                class="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                                >{{ dateReturn }}</label
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </td>
</template>
